.navContainer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: auto;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    z-index: 50;
    opacity: 0.9;
}

.navBar {
    width: 100%;
    height: 100px;
    display: flex;
    border-bottom: 3px solid #d9d9d9;
    padding: 0 5rem;
    background-color: white;
    opacity: 0.9;
    align-items: center;
    max-height: 0;
}

.menuBar {
    display: none;
    gap: 1rem;
}

.menuIcon {
    margin-right: 1rem;
    display: none;
    cursor: pointer;
}

.navMenu {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.navRight {
    width: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.logo {
    display: flex;
    align-items: center;
}

.productCategory {
    margin-left: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productCategory ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
}

.productCategory a {
    text-decoration: none;
    color: inherit;
}

.productCategory li {
    padding: 8px 12px;
}

.productCategory li:hover {
    color: rgb(29, 78, 216);
    font-weight: bold;
    transform: scale(1.1);
}

.searchContainer {
    position: relative;
}

.searchResults {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    text-align: start;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    background-color: white;
    box-shadow: 0px 4px 5px rgba(0,0,0,0.2);
    list-style: none;
    margin: 0;
    padding: 0;
}

.searchResults li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    white-space: nowrap; /* 텍스트가 줄 바꿈되지 않도록 설정 */
    overflow: hidden;
    text-overflow: ellipsis; /* 넘친 텍스트에 대해 줄임표(...)로 표시 */
    cursor: pointer;
}

.selectedItem {
    background-color: #f0f0f0; /* 선택된 항목의 배경색 */
    font-weight: bold; /* 선택된 항목의 글꼴 두껍게 */
    /* 추가적인 스타일링을 원하는대로 적용할 수 있습니다 */
}

.searchInput {
    width: 100%;
    border: 2px solid rgb(29, 78, 216);
    background-color: white;
    border-radius: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 22px;
    position: relative;
}
.hidden {
    display: none;
}
.searchInput input {
    width: 100%;
    font-size: 16px;
    outline: none;
}

.searchInput input::placeholder {
    font-size: 16px;
    line-height: 20px;
    color: #767676;
}

.userCategory a {
    text-decoration: none;
    color: inherit;
}

.userCategory li {
    padding: 8px 12px;
}

.userCategory ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
}

.userCategory li:hover {
    color: rgb(29, 78, 216);
    font-weight: bold;
    transform: scale(1.1);
}

.iconContainer {
    display: flex;
    justify-content: flex-end;  /* 오른쪽으로 정렬 */
    align-items: center;
    margin-right: 1rem;  /* 오른쪽에서 1rem 떨어지게 */
}

.inputSearchicon {
    width: 30px;
    height: 30px;
}

.icon:hover {
    cursor: pointer;
}

.iconTitle {
    margin-left: 0.5rem;
}

.categories {
    overflow: hidden; /* 기본적으로 숨김 처리 */
    max-height: 0px; /* 기본적으로 메뉴 숨김 */
    transition: max-height 0.5s ease-in-out; /* 트랜지션 효과 */
    text-align: start;
    display: flex;
    justify-content: space-around;
}

.categoryTitle {
    color: #333;
    margin-bottom: 0px;
    font-size: 23px;
    align-content: center;
}

.subcategoryList {
    color: #999;
    list-style: none;
    margin: 0;
    padding: 0;
}

.subcategoryItem {
    font-size: 15px;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
    transition: transform 0.5s ease;
}

.subcategoryItem:hover {
    color: black;
    transform: translateX(5px);
}

.categoryLink {
    display: flex;
    text-decoration: none;
    color: inherit;
}

.subcategoryLink {
    text-decoration: none; /* 링크의 밑줄 제거 */
    color: inherit; /* 링크의 색상을 상위 요소의 색상으로 상속 */
}

.categoryLink:hover,
.subcategoryLink:hover {
    color: inherit;
}

.open {
    max-height: 500px;
    transition: max-height 1.5s ease-out;
}

.close {
    transition: max-height 1.5s ease;
    overflow: hidden;
}

.show {
    display: flex;
    max-height: 300px; /* 펼쳐졌을 때 최대 높이 */
    border-bottom: 1px solid #d9d9d9;
    overflow-y: auto; /* 스크롤 활성화 */
}

.mobileBottomNav {
    padding: 0.5rem 0;
    position: relative;
    display: none;
    background-color: white;
    
    border-top: 2px solid #d9d9d9;
    justify-content: space-around;
}

.searchMobileContainer {
    
}

.productUserCategory {
    background-color: white;
    width: 100%;
}

.productUserCategory ul {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
}

.searchOpen {
    max-height: 500px;
    display: flex;
}

.searchIcon, .categoryIcon  {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .navBar {
        padding: 0 1rem;
    }

    .productCategory,
    .userCategory {
        margin-left: 10px;
    }

    .searchInput input {
        width: 95%;
        /* 입력창 너비 조정 */
    }

    .iconTitle {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .navContainer {
        position: fixed;
        opacity: 1;
    }

    .navBar {
        padding: 0;
        flex-direction: column;
        height: auto;
        border-bottom: 2px solid #d9d9d9;
    }

    .navLeft {
        display: none;
        /* width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; */
    }
    .menuBar {
        display: flex;
    }
    .menuIcon {
        display: flex;
    }

    .navMenu {
       display: none;
    }

    .categories {
        flex-wrap: wrap; /* 요소들을 여러 줄에 걸쳐 표시 */
        flex-direction: row; /* 요소들을 가로로 배열 */
        justify-content: space-evenly
    }

    .categoryItem {
        width: 50%;
    }

    .subcategoryList {
        margin-left: 30px;
        margin-top: 10px; /* 서브카테고리 간격 조정 */
    }

    .categoryTitle {
        font-size: 18px; /* 카테고리 제목 폰트 크기 조정 */
        margin-bottom: 5px; /* 카테고리 제목과 서브카테고리 간격 조정 */
    }

    .mobileBottomNav {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .searchResults {
        top: auto;
        bottom: 100%;
        /* 약간 위로 간격 추가 */
    }

    .searchInput {
        position: absolute;
        top: -3rem;
        right: 7rem;
        width: 60%;
        height: 35px;
        margin: 3px;
    }

    .searchInput input {
        font-size: 14px;
    }
    
    .searchInput input::placeholder {
        font-size: 14px;
    }

    .inputSearchicon {
        width: 25px;
        height: 25px;
    }

    .searchIcon {
        position: absolute;
        top: -3rem;
        right: 1rem;
    }

    .categoryIcon {
        position: absolute;
        top: -6rem;
        right: 1rem; 
    }
}
