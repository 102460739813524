.loadingCircle {
    width: 50px;
    height: 50px;
    margin: 10px auto;

    border: 5px solid #e3e3e3;
    border-bottom: 5px solid #0f4fe6;
    border-radius: 50%;

    animation: load 1.5s linear infinite;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}