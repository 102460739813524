/* 체크박스와 라벨 정렬 */
.mobileContainer {
    width: 100%;
    padding: 1rem;
    display: none;
    align-items: flex-start;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
}

.orderImg {
    max-width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    object-fit: fill;
}

/* 체크박스 */
.checkBox {
    margin-right: 10px; /* 체크박스와 콘텐츠 간격 조정 */
}

.custom_checkbox {
    display: none; /* 실제 체크박스 숨김 */
}

.checkbox_icon::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border: 1px solid #9da3a5;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
}

/* 체크박스 선택 시 디자인 */
.custom_checkbox:checked + .checkbox_icon::before {
    background: url('https://intranet.adef.co.kr/asset/images/ic_check.png') #0b74d6 no-repeat center;
    background-size: 14px;
    border: none;
}

.orderInfo {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
    margin-left: 1rem;
}

.orderName {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
}

.orderOption {
    color: #b4b4b4;
    font-size: 12px;
    margin-bottom: 4px;
}

.quantityInput {
    width: 30px;
    text-align: center;
    font-size: 14px;
}

.quantityButton {
    margin: 0 0.3rem;
    padding: 4px 8px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    font-size: 14px;
    border-radius: 4px;
}

/* 가격 정보 */
.orderTotal {
    text-align: end;
}

.priceInfo {
    font-size: 13px;
    color: #333;
    margin-bottom: 8px;
}

.priceInfo p {
    margin: 4px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.total {
    font-size: 14px;
    font-weight: bold;
    color: #0b74d6;
}

@media (max-width: 780px) {
    .mobileContainer {
        display: flex;
    }
}