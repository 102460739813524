.qnaListContainer {
    width: 100%;
}
.qnaFormContainer {
    padding: 24px;
    border-bottom: 1px solid #ccc;
    text-align: right;
}

.qnaForm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.qnaForm textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    width: 100%;
}

.qnaForm button {
    padding: 10px 20px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.qnaForm button:hover {
    background-color: #0056b3;
}
