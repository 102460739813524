.mainVisal {
	width: 100%;
}

/* .mainVisal h2 {
	margin: 30px 0;
}

.mainVisal button {
	margin-top: 20px;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	background: red;
	color: white;
	font-weight: bold;
	font-size: 14px;
}

.mainVisal button:hover {
	color: red;
	background: white;
	transition: all 0.5s;
	transform: scale(1.05);
} */