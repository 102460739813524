
.qnaList {
    text-align: left;
    list-style: none;
    padding: 24px;
}

.qnaList li {
    align-items: center;
    border-bottom: 2px solid rgba(29, 78, 216);
    padding: 20px 0;
}

.qnaList li:last-child {
    border-bottom: none;
}

.question,
.answer {
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.answer {
    background-color: #f3f4f6;
}

.userInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}

.userInfo span:first-child {
    display: inline-block;
    margin-right: 8px;
}

.questionContent,
.answerContent {
    padding-left: 1rem;
    font-size: 14px;
    color: #333;
    line-height: 1.6;
}

.questionLable,
.answerLable {
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.questionLable {
    background-color: #007bff; /* 파란색 */
}

.answerLable {
    background-color: #28a745; /* 녹색 */
}

.answerContanier {
    display: flex;
    gap: 10px
}

.answerArrow {
    font-size: 50px;
}

.date {
    color: #9CA3AF;
}

@media (max-width: 768px) {
    .qnaList, .question, .answer {
        padding: 14px;
    }

    .userInfo {
        flex-direction: column;
        font-size: 12px;
    }

    
}
