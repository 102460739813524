/* 홈 컨테이너 스타일 */
.homeContainer {
  margin-bottom: 3rem;
  width: 70%;
  margin: auto;
}

.homeBanner {
  display: flex;
  justify-content: center;
  margin: 5rem auto;
}


@media screen and (max-width: 768px) {
  .homeContainer {
    width: 90%;
    margin-bottom: 0;
  }

  .homeBanner {
    margin: 1rem;
  }
}