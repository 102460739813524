.orderContainer {
    width: 80%;
    text-align: left;
    margin: 0 auto 5rem auto;
}

.scrollableList {
    max-height: 600px; /* 원하는 높이로 조정 */
    overflow-y: auto;
}
.orderItems ul{
    margin: 0;
    padding: 0.5rem;

}
.orderItems li{
    margin: 0;
    padding: 0;
}

.itemTotalPrice {
    font-size: 17px;
    font-weight: 600;
}
.totalPrice{
    font-size: 25px;
    font-weight: bold;
}
.orderTitle {
    font-size: 20px;
    font-weight: bold;
    padding: 26px 0 16px 0;
    border-bottom: 1px solid #999;
}

.orderInfo {
    width: 100%;
    margin: 0;
    font-size: 15px;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}
.orderInfoTitle {
    font-size: 17px;
    font-weight: bold;
    padding: 26px 0 16px 0;
}

.userName {
    width: 20%;
    height: 40px;
    padding: 10px 0 10px 1rem;
    border: 1px solid #ccc;
}

.orderPhonNum {
    margin: 1rem 0;
    width: 100%;
    height: 40px;
    font-size: 15px;
    justify-content: space-between;
}

.phoneNumFirst {
    width: 20%;
    height: 40px;
    padding: 10px 0 10px 1rem;
    border: 1px solid #ccc;
    margin-right: 0.5rem;
}

.phoneNum {
    width: 20%;
    height: 40px;
    padding: 10px 0 10px 1rem;
    border: 1px solid #ccc;
    margin: 0 0.5rem;
}

.orderInfoContainer {
    width: 50%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.op {
    font-size: 20px;
    transform: rotate(90deg);
    display: inline-block;
}


.priceTitle {
    font-size: 17px;
    font-weight: bold;

}

.price {
    font-size: 20px;
    font-weight: bold;
    color: #004393
}

.paymentButton  {
    background-color: #004393; /* 배경 색상 */
    color: white; /* 글자 색상 */
    padding: 15px 25px; /* 패딩 */
    font-size: 16px; /* 글자 크기 */
    font-weight: bold; /* 글자 두께 */
    border: 2px solid transparent; /* 테두리 설정 */
    border-radius: 5px; /* 모서리 둥글게 */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* 전환 효과 */
  }

.paymentButton:hover {
    background-color: white; /* 배경 색상 변경 */
    color: #004393; /* 글자 색상 변경 */
    border-color: #004393; /* 테두리 색상 변경 */
  }
  
.totalPriceContainer {
    text-align: center;
    position: fixed;
    top: 30%; 
    right: 10px; 
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    z-index: 50; 
}
.totalPriceContainer ul {
    margin: 0;
    padding: 3rem;
}

.error {
    border-color: red;
}

.coupon {
    width: 50%;
    height: 40px;
    font-size: 15px;
    padding: 10px 0 10px 1rem;
    border: 1px solid #ccc;
    margin: 0 0.5rem;
}

.point {
    width: 20%;
    height: 40px;
    font-size: 15px;
    padding: 10px 0 10px 1rem;
    border: 1px solid #ccc;
    margin: 0 0.5rem;
}

@media (max-width: 780px) {
    .orderContainer {
        width: 90%;
    }
    .userName {
        width: 25%;
    }

    .orderPhonNum {
        font-size: 12px;
    }
    
    .phoneNumFirst {
        width: 25%;
    }
    .phoneNum {
        width: 25%
    }
    
    .orderInfo {
        font-size: 12px;
    }
    .orderInfoContainer {
        width: 80%;
        
    }
    
    .totalPriceContainer {
        position: relative;
        z-index: 0;
        right: 0;
    }

    .totalPriceContainer ul {
        padding: 1rem;
    }
    
    .coupon {
        width: 80%;
    }
    .point {
        width: 30%;
    }
}