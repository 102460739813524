.myCartListComp {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-bottom: 1rem;
}

.myCartListComp table {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-top: 3px solid #004393;
    border-collapse: collapse;
  }
  
.myCartListComp table thead tr th  {
    border-bottom: 2px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #004393;
}

.myCartListComp table tbody tr td {
    border-bottom: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
}

.orderName {
    padding-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    text-align: start;
    font-size: 17px;
    font-weight: bold;
}

.orderPrice {
    font-size: 20px;
    font-weight: bold;
}

.orderTotal {
    font-size: 20px;
    font-weight: bold;
}

.shippingCostTotal {
    font-size: 11px;
    color: #999
}

.quantityContainer {
    justify-content: center;
    display: flex;
}

.quantityInput {
    width: 30%;
    text-align: center;
}
.quantityButton {
    width: 30px;
    margin: 0 0.2rem 0 0.2rem;
    padding: 5px 10px;
    cursor: pointer; 
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    font-weight: bold;
}

.title, .option {
    margin: 0;
}
.option {
    color: #9CA3AF;
    font-size: 13px;
}

.priceInfo {
    font-size: 14px; /* 원하는 글자 크기로 변경하세요 */
    color: #333; /* 원하는 색상으로 변경하세요 */
    margin-bottom: 8px; /* 아래 여백 조절 */
}

.priceInfo p {
    margin: 4px 0; /* 단락 간격 조절 */
}

.linkWrapper {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 780px) {
    .myCartListComp table {
        display: none;
    }
}