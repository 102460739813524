/* 홈 컨테이너 스타일 */
.homeContainer {
  padding-top: 1rem;
  position: relative;
  justify-content: center;
}
  
.productListContainer {
  width: 80%;
  margin: 0 auto;
}

.productListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  border-bottom: 2px solid blue;
}

.productListTitle {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;      
  text-overflow: ellipsis; 
}


.sortSelect {
  padding: 8px 16px;
  font-size: 14px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease-in-out;
}

.sortSelect:hover,
.sortSelect:focus {
  border-color: #007bff;
}


.productListContainer {
  flex-wrap: wrap;
  overflow: hidden;
}

.productListBody {
  padding: 1rem;
}
.productList {
  list-style: none;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.productList li {
  display: block;
  margin-left: 0;
  margin-top: 0;
  width: 100%; /* 부모 그리드 셀의 너비에 맞게 설정 */
}

.blurEffect {
  filter: blur(4px);
  height: 60px;
}

.moreButtonContainer {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgb(78, 72, 72);
  font-weight: bold;
  font-size: 23px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,  rgba(255, 255, 255, 1) 100%);
}
.moreButtonContainer:hover {
  font-size: 27px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.carouselContainer {
  display: block;
}

@media (max-width: 1280px) {
  .productList {
    padding: 0;
  }

}

@media (max-width: 1024px) {
  .productList {
    /* grid-template-columns: repeat(3, 1fr); */
    
  }
  .homeContainer {
    padding: 0;
  }

}

@media (max-width: 768px) {
  .productListTitle {
    font-size: 18px;
  }

  .productList {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    
  }

  .productListContainer {
    width: 100%;
    margin: 0;
  }
  /* .productList li{
    margin: 10px;

  } */
  
  .sortSelect {
    font-size: 12px;
    padding: 4px 8px;
  }
}

@media screen and (max-width: 480px) {
  .productList {
    /* grid-template-columns: repeat(2, 1fr); */
    
  }
  .productListTitle {
    font-size: 14px;
  }
  .homeContainer {
    width: 100%;
    margin: 0;
    padding: 0;
  }

}