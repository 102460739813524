.addresContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.findAddress {
    width: 120px;
    height: 40px;
    margin-left: 1rem;
    background-color: #2196F3; /* 파란색 배경 */
    color: white; /* 흰색 글자 */
    border: none; /* 테두리 없앰 */
    border-radius: 5px; /* 모서리 둥글게 */
    cursor: pointer; /* 마우스 오버 시 커서 변경 */
    font-weight: bold; /* 글자 굵게 */
    transition: background-color 0.3s ease; /* 배경색 변경 애니메이션 */
    display: flex; /* Flexbox를 이용한 배치 */
    justify-content: center; /* 가로 방향 중앙 정렬 */
    align-items: center; /* 세로 방향 중앙 정렬 */
}

.findAddress:hover {
    background-color: #1976D2; /* 마우스 오버 시 배경색 어둡게 변경 */
}

.findAddress:focus {
    box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.5); /* 포커스 시 주변에 그림자 효과 */
}


.address {
    width: 100%;
    height: 40px;
    font-size: 15px;
    padding: 10px 0 10px 1rem;
    border: 1px solid #ccc;
    margin: 0.5rem 0;
}

.error {
    border-color: red;
}

@media (max-width: 780px) {
    .address {
        font-size: 12px;
    }
}