
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @font-face {
  font-family: "NanumSquareNeoOTF-Rg";
  font-weight: normal;
  src: url("./font/NanumSquareNeoOTF-Rg.otf") format("truetype");
} */

.APP {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.Router {
  margin-top: 100px;
}

.Footer {
  margin-bottom: 35px;
}

@media (max-width: 1440px) {
  .Footer {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .Router {
    margin-top: 0px;
  }

  .Router {
    margin-bottom: 75px;
  }

  .Footer {
    display: none;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animateSlideUp {
  animation: slideUp 1.5s ease-out forwards;
}
